export const FirebaseConfig = {
	"projectId": "apnisawari01",
	"appId": "1:1050353476300:web:84c7b76709e57a91b01869",
	"databaseURL": "https://apnisawari01-default-rtdb.firebaseio.com",
	"storageBucket": "apnisawari01.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyANjCXnebqLtzHbQerWO_HJ3ckPmim2YgM",
	"authDomain": "apnisawari01.firebaseapp.com",
	"messagingSenderId": "1050353476300",
	"measurementId": "G-SZMD3F1E69"
};